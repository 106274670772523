import { useAuthStore } from "~/auth/stores/auth"
import useAccessControl from "~/layers/accessControl/composables/accessControl"

export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useAuthStore()
  const user = computed(() => auth.user)
  if (user.value && Object.keys(user.value).length > 1) {
    const { hasRoles } = useAccessControl()
    const roles = to?.meta?.roles as string | string[]
    if (!hasRoles(roles)) return navigateTo("/restricted")
  }
})
